<template>
  <head>
    <title>オーダーメイドの測定結果</title>
  </head>
  <aheader/>
  <div class="block">
    <section class="hero is-warning">
      <div class="hero-body">
        <p class="title">
          オーダーメイドの測定結果
        </p>
        <p class="subtitle">
          来店時に測定した結果を掲載しています
        </p>
      </div>
    </section>
  </div>
  <div class="block">
    <section class="hero is-small is-white">
      <div class="hero-body">
        <div class="content is-normal">
          <h1 style="text-align:left">〇〇〇様の測定結果</h1>
          <p style="text-align:left">来店時に測定器で測定した結果を掲示しています。オーダーメイドでお布団を作る際はこの結果を元に、あなたにあったお布団を提供します。</p>
        </div>
      </div>
    </section>
  </div>
  <br/>
  <div class="block">
    <div class="content is-medium">
      <p>測定の結果、あなたは・・・</p>
      <h1>かめさん型</h1>
      <img height="100" width="896" style="float: top;" src="kame.png">
      <p>でした</p>
      <br/>
      <h4><u>かめさん型の特徴</u></h4>
      <p>In fermentum leo eu lectus mollis, quis dictum mi aliquet.
      <br/>Morbi eu nulla lobortis, lobortis est in, fringilla felis.
      <br/>Aliquam nec felis in sapien venenatis viverra fermentum nec lectus.
      <br/>Ut non enim metus.</p>
      <img height="209" width="300" style="float: top;" src="zu.jpg">
      <br/>
      <br/>
      <h4><u>かめさん型の方に合うお布団</u></h4>
      <p>In fermentum leo eu lectus mollis, quis dictum mi aliquet.
      <br/>Morbi eu nulla lobortis, lobortis est in, fringilla felis.
      <br/>Aliquam nec felis in sapien venenatis viverra fermentum nec lectus.
      <br/>Ut non enim metus.</p>
      <br/>
      <br/>
      <h4><u>かめさん型の方へのアドバイス</u></h4>
      <p>In fermentum leo eu lectus mollis, quis dictum mi aliquet.
      <br/>Morbi eu nulla lobortis, lobortis est in, fringilla felis.
      <br/>Aliquam nec felis in sapien venenatis viverra fermentum nec lectus.
      <br/>Ut non enim metus.</p>
    </div>
  </div>
  <afooter/>
</template>
